
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <p class="card-title-desc">รายการสั่งซื้ออะไหล่</p> -->
            <div class="row justify-content-md-center">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <!-- Search -->
                  <div class="col-sm-4 col-md-4">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center"> สาขา:</label>
                      <multiselect
                        v-model="branchIdSearch"
                        :options="localData"
                        label="nameTh"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center m-2">
                        รหัสการสั่งซื้อ:
                      </label>
                      <b-form-input
                        v-model="filter.poCode"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control"
                        @keyup.enter="handleSearch"
                      ></b-form-input>
                    </div>
                  </div>

                  <div class="col-md-2 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label class="d-inline align-items-center m-2">
                        วันที่สั่งซื้อ:</label
                      >
                      <!-- disabled="disabled"  -->
                      <date-picker
                        v-model="filter.poDate"
                        :first-day-of-week="1"
                        format="YYYY-MM-DD"
                        @input="handleSearch"
                        value-type="format"
                        lang="en"
                      ></date-picker>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-4">
                    <div class="mb-3 position-relative">
                      <label for="branchId">สถานะ:</label><br />
                      <multiselect
                        v-model="status"
                        :options="optionStatus"
                        label="name"
                        @input="handleSearch"
                        :show-labels="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <!-- <div class="col-md-10 float-end"></div> -->
                  <div class="col-md-12 col-sm-8 float-end text-end">
                    <btnComponent
                      @click="handleSearch"
                      changeStyle="search"
                    ></btnComponent
                    >&nbsp;&nbsp;&nbsp;&nbsp;
                    <router-link
                      :to="{ name: 'add-po' }"
                      class="btn btn-primary float-end"
                    >
                      <i class="mdi mdi-plus me-1"></i>เพิ่มการสั่งซื้อ
                    </router-link>
                  </div>
                  <div class="row float-end"></div>
                </div>
              </div>
            </div>
            <div class="row justify-content-md-center mt-2">
              <div class="col-sm-12 col-md-12">
                <div class="row align-items-center">
                  <div class="col-sm-12 col-md-12">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        แสดงผล&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                          @input="PageChange"
                        ></b-form-select
                        >&nbsp;รายการ
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                </div>
              </div>
            </div>
            <b-skeleton-wrapper :loading="loading">
              <!-- skeleton  -->
              <template #loading>
                <b-skeleton-table></b-skeleton-table>
              </template>

              <!-- Table -->
              <div class="table-responsive mb-0">
                <tableData :fields="fields" :items="rowPo">
                  <template #cell(grandTotalPrice)="rowPo">
                    <p class="text-end">{{ Number(rowPo.item.grandTotalPrice).toLocaleString() }}</p>
                  </template>
                  <template #cell(poDate)="rowPo">
                    <changeDate :date="rowPo.item.poDate"></changeDate>
                  </template>
                  <template #cell(index)="rowPo" v-if="this.currentPage > 1">
                    {{ rowPo.index + 1 + (currentPage * perPage - perPage) }}
                  </template>
                  <template #cell(index)="rowPo" v-else>
                    {{ rowPo.index + 1 }}
                  </template>
                  <!-- edit table -->
                  <template #cell(status)="rowPo">
                    <span
                      class="badge bg-warning font-size-12 ms-2"
                      v-if="rowPo.item.status === 'DFT'"
                    >
                      ร่าง
                    </span>
                    <span
                      class="badge bg-success font-size-12 ms-2"
                      v-if="rowPo.item.status === 'APV'"
                    >
                      ยืนยันสั่งซื้อแล้ว
                    </span>
                  </template>

                  <!-- end edit table -->
                  <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                      <span aria-hidden="true">&check;</span>
                      <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                      <span aria-hidden="true">&nbsp;</span>
                      <span class="sr-only">Not selected</span>
                    </template>
                  </template>

                  <!-- edit table -->
                  <template v-slot:cell(action)="rowPo">
                    <router-link
                      class="px-2 text-primary"
                      v-b-tooltip.hover
                      title="Edit"
                      :to="{
                        name: 'edit-po',
                        params: { poId: rowPo.item.poId },
                      }"
                    >
                      <i class="uil uil-pen font-size-18"></i>
                    </router-link>
                    <a
                      class="px-2 text-danger"
                      v-b-tooltip.hover
                      title="Delete"
                      @click="alert(rowPo.item.poId)"
                    >
                      <i class="uil uil-trash-alt font-size-18"></i>
                    </a>
                    <a
                      class="px-2 text-success"
                      v-b-tooltip.hover
                      title="Print"
                      @click="getPoPrint(rowPo.item.poId)"
                    >
                      <i class="uil-print font-size-18"></i>
                    </a>
                  </template>

                  <!-- End edit table -->
                </tableData>
              </div>
              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="col">
                    <label class="d-inline-flex align-items-center">
                      หน้า {{ currentPage }} จาก {{ this.totalPage }} ทั้งหมด
                      {{ totalRecord }} รายการ
                    </label>
                    <div
                      class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-end
                      "
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRecord"
                          :per-page="perPage"
                          @change="ChangePage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </b-skeleton-wrapper>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import btnComponent from "@/components/btnComponent.vue";
import ChangeDate from "@/components/changeDateComponent";
import tableData from "@/components/tablecomponent";
// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: appConfig.po,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    btnComponent,
    ChangeDate,
    tableData,
    // branchComponent,
  },

  data() {
    return {
      loading: undefined,

      title: appConfig.po,
      items: [
        {
          text: "อะไหล่",
          active: true,
        },
       
        {
          text: appConfig.po,
          active: true,
        },
      ],
      optionStatus: [
        {
          status: "APV",
          name: " ยืนยันการรับแล้ว ",
        },
        {
          status: "DFT",
          name: " ร่าง ",
        },
      ],
      status: "",
      branchId: "",
      branchIdSearch: "",
      localDataBranchId: [],
      dataPrint: [],
      rowSup: [],
      poId: this.$route.params.poId,
      totalPage: "",
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      total: "",
      rowpage: "",
      totalRecord: "",
      rowPo: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: { poDate: "", poCode: "" },
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectedUsers: [],
      isSelectedAll: false,
      search: true,
      fields: [
        {
          label: "ลำดับ",
          key: "index",
        },
        {
          key: "poCode",
          label: "รหัสการสั่ง",
          sortable: true,
        },
        {
          key: "supplierNameTh",
          label: "ผู้แทนจำหน่าย",
          sortable: true,
        },
        {
          key: "poDate",
          label: "วันที่สั่ง",
          sortable: true,
        },
        {
          key: "createdBy",
          label: "ผู้ทำรายการ",
          sortable: true,
        },
        {
          key: "grandTotalPrice",
          label: "ยอดเงิน",
          sortable: true,
        },
        {
          key: "branchName",
          label: "สาขา",
          sortable: true,
        },
        {
          key: "status",
          label: "สถานะ",
        },
        {
          key: "action",
          label: appConfig.action,
        },
      ],
    };
  },

  computed: {
    /**
     * Total no. of records
     */
  },
  mounted() {
    // Set the initial number of items
  },
  created() {
    // this.getSup();
    this.getDataPo();
    this.getLocalData();
  },
  methods: {
    getPoPrint: function (poId) {
      //  console.log(this.poId);
      this.poIdPrint = poId;
      this.loading = true;
      useNetw
        .get("api/part-po/print", {
          params: {
            poId: this.poIdPrint,
          },
        })
        .then((response) => {
          this.dataPrint = response.data.url;
          window.location = this.dataPrint;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    getLocalData() {
      const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
      this.localData = user.userAccessBranch;
      this.branchId = user.branchId;

      const localDataBranchId = [];
      this.localData.forEach((item) => {
        localDataBranchId.push(item.branchId);
      });
      this.localDataBranchId = localDataBranchId;
    },
    handleSearch() {
      if (this.branchIdSearch === "") {
        this.localDataBranchId;
      } else {
        this.localDataBranchId = [this.branchIdSearch.branchId];
      }
      this.getDataPo();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.rowPo = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(rowPo) {
      this.selected = rowPo;
      // this.getData();
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getDataPo: function () {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/part-po", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.localDataBranchId,
            poDate: this.filter.poDate,
            poCode: this.filter.poCode,
            status: this.status.status,
          },
        })
        .then((response) => {
          this.rowPo = response.data.data;
          this.perPage = response.data.perPage;
          this.from = response.data.from;
          this.totalRecord = response.data.total;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / this.perPage);
          // console.log(this.rowPo);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.message + "<br>" + err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    //  getSup: function () {
    //   // this.overlayFlag = true;
    //   this.loading = true; //skeleton true
    //   useNetw
    //     .get("api/master/suppliers", {
    //       params: {
    //         branchId: this.branchId,
    //       },
    //     })
    //     .then((response) => {
    //       this.rowSup = response.data.data;
    //       // console.log(response);
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       // this.overlayFlag = false;
    //       this.loading = false; //skeleton false
    //     });
    // },
    deletePo: function (rowPo) {
      this.poData = rowPo;
      // console.log(this.partData);
      this.loading = true; //skeleton true
      useNetw
        .delete("api/part-po/delete", {
          data: {
            poId: this.poData,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          this.getDataPo();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alert(rowPo) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deletePo(rowPo);
          }
        });
    },
    ChangePage(page) {
      this.currentPage = page;
      this.getDataPo();
    },
    PageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getDataPo();
    },
  },
  middleware: "authentication",
};
</script>
